import React from 'react'
import { contractsData } from '../../Services/DataUtils'

const Contracts = () => {
  return (
    <section className='contracts-section'>
      <div className="contracts-inner text-[#c1c2c5] bg-[#1a1b1e] p-3 pt-5 rounded-xl h-[89vh]">
        {contractsData.map((item) => {
          return <div key={item.id} className="main flex justify-between items-center pt-1">
            <p>{item.title}</p>
            <p>{item.parsent}</p>
          </div>
        })}
      </div>
    </section>
  )
}

export default Contracts
