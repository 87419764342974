import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from '../pages/Home/HomePgae'
import MainPage from '../pages/Main/MainPage'

const Routers = () => {
  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path='/main' element={<MainPage />} />
    </Routes>
  )
}

export default Routers
